/* mixins*/

@mixin font($font, $size: 12px, $style: normal, $weight: normal, $color: #000) {
    font-family: $font;
    font-size: $size;
    font-style: $style;
    font-weight: $weight;
    color: $color;
}

@mixin mybutton($fon, $color, $padingside: 35px, $radius: 0) {
    padding: $padingside;
    border-radius: $radius;
    color: $color;
    background-color: $fon;
    cursor: pointer;


    &:hover {
        background-color: lighten($fon, 5%);
    }
}

@mixin mobile {
    @media (max-width: #{$tablet-width - 1px}) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$desktop-width}) and (max-width: #{$biggest-width - 1px}) {
        @content;
    }
}

@mixin bigdesktop {
    @media (max-width: #{$biggest-width}) {
        @content;
    }
}

$tablet-width: 768px;
$desktop-width: 1024px;
$biggest-width: 1600px;

/* variables*/

@font-face {
    font-family: 'SFUIText-Regular';
    src: url('fonts/SFUIText-RegularG2.eot?#iefix') format('embedded-opentype'), url('fonts/SFUIText-RegularG2.otf') format('opentype'), url('fonts/SFUIText-RegularG2.woff') format('woff'), url('fonts/SFUIText-RegularG2.ttf') format('truetype');
    //url('fonts/SFUIText-RegularG2.svg#SFUIText-RegularG2') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SFUIText-Bold';
    src: url('fonts/SFUIText-Bold.eot?#iefix') format('embedded-opentype'), url('fonts/SFUIText-Bold.otf') format('opentype'), url('fonts/SFUIText-Bold.woff') format('woff'), url('fonts/SFUIText-Bold.ttf') format('truetype');
    //url('fonts/SFUIText-Bold.svg#SFUIText-Bold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SFUIText-Light';
    src: url('fonts/SFUIText-Light.eot?#iefix') format('embedded-opentype'), url('fonts/SFUIText-Light.otf') format('opentype'), url('fonts/SFUIText-Light.woff') format('woff'), url('fonts/SFUIText-Light.ttf') format('truetype');
    //url('fonts/SFUIText-Light.svg#SFUIText-Light') format('svg');
    font-weight: normal;
    font-style: normal;
}




$font: 'SFUIText-Regular', Verdana, Helvetica, sans-serif;
$fontBold: 'SFUIText-Bold', Verdana, Helvetica, sans-serif;
$fontLight: 'SFUIText-Light', Verdana, Helvetica, sans-serif;
$grey: #dcdcdc;




$clientColor: #F25140; 

.eve-detail{
    &__tipe {
        color: $clientColor
    }
}


.eve-svg-icons,
.eve-main .eve-item svg {
    fill: $clientColor
}

.eve-link {
    color: $clientColor;
}


.eve-btn {
    background-color: $clientColor;
    border-color: $clientColor;

    &:hover {
        background-color: darken($clientColor, 7%);
        border-color: darken($clientColor, 7%);
    }
}